import { useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface FormValues {
  name: string;
  email: string;
  message: string;
}

const ContactSchema = Yup.object().shape({
  name: Yup.string().max(100, 'Max of 100 characters').required('Required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .min(6, 'Please enter at least 6 characters')
    .max(100, 'Max of 100 characters')
    .required('Required'),
  message: Yup.string().max(3000, 'Max of 3000 characters'),
});

export const ContactForm: React.FC = () => {
  const [formError, setFormError] = useState('');
  const [formSent, setFormSent] = useState(false);
  const CForm = chakra(Form);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialValues: FormValues = {
    name: '',
    email: '',
    message: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContactSchema}
      onSubmit={async (
        values: FormValues,
        actions: FormikHelpers<FormValues>,
      ) => {
        setFormSent(false);

        try {
          setFormError(''); // reset form error

          // Google reCaptcha v3 verification
          if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
          }
          executeRecaptcha('enquiryFormSubmit')
            .then(async (gReCaptchaToken) => {
              const gr_res = await fetch(
                '/.netlify/functions/gcaptcha-verify',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ gRecaptchaToken: gReCaptchaToken }),
                },
              );

              if (!gr_res.ok) {
                setFormError('reCaptcha Failed.' + gr_res.statusText);
                throw new Error(
                  'reCaptcha verification failed. Please try again.',
                );
              }

              // Send form data
              const res = await fetch('/.netlify/functions/contact', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(values),
              });

              if (res.ok) {
                actions.resetForm();
                setFormError('');
                setFormSent(true);
              }
            })
            .catch((err) => {
              setFormError('reCaptcha Failed.\n' + err.message);
              throw new Error(
                'reCaptcha verification failed. Please try again.',
              );
            });
        } catch (err) {
          setFormError(err.message);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <CForm w={'100%'} maxW={'30rem'} textAlign={'center'}>
          {(formSent || formError) && (
            <Box my={8}>
              {formSent && (
                <Alert status="success" color={'#2D3748'} borderRadius={'10px'}>
                  <AlertIcon />
                  Message received! We'll get back to you as soon as possible.
                </Alert>
              )}
              {formError && (
                <Alert status="error" color={'#2D3748'} borderRadius={'10px'}>
                  <AlertIcon />
                  {formError}
                </Alert>
              )}
            </Box>
          )}
          <Field name="name">
            {({ field, form }) => (
              <FormControl
                id="name"
                my={6}
                isInvalid={form.errors.name && form.touched.name}
                isRequired
              >
                <FormLabel>Name</FormLabel>
                <Input type="name" {...field} />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email">
            {({ field, form }) => (
              <FormControl
                id="email"
                my={6}
                isInvalid={form.errors.email && form.touched.email}
                isRequired
              >
                <FormLabel>Email</FormLabel>
                <Input type="email" {...field} />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="message">
            {({ field, form }) => (
              <FormControl
                id="message"
                my={6}
                isInvalid={form.errors.message && form.touched.message}
              >
                <FormLabel>Message</FormLabel>
                <Textarea {...field} />
                <FormErrorMessage>{form.errors.message}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button
            type="submit"
            // isDisabled={btnDisable}
            minW={'11rem'}
            m={'2.5rem auto 0'}
            p={4}
            isLoading={props.isSubmitting}
            loadingText={'Sending...'}
            spinnerPlacement="end"
          >
            Send message
          </Button>
          <Box display={'flex'} alignItems={'center'} flexDir={'column'}>
            <p style={{ paddingTop: '1rem' }}>
              This page is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a href="https://policies.google.com/terms">
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </p>
          </Box>
        </CForm>
      )}
    </Formik>
  );
};
